import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import { EventType } from '../@types/eventType.d';
import { Event } from '../@types/event.d';

import { RootState } from '../store';
import { setEventSelected } from '../store/User/UserSlice';

import { addHold, getEventsByClientAndType } from '../services/apiService';
import { Dropdown } from 'react-bootstrap';
import { Loading, SkeletonCard, SkeletonTable } from '../components';
import calendaricon from '../assets/icons/calendar.png'
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

let EV_COUNTRIES: string[] = [];

interface EventTypeProps {
    eventType?: EventType;
    
    sortBy?: string;
    ascending?: boolean;

    selectedCountry?: string;
    resetSelectedCountry?: any;
}

const DisplayEventType: React.FC<EventTypeProps> = ({ eventType, sortBy, ascending, selectedCountry, resetSelectedCountry }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useAuth0();
    const { eventSelected, currentHolds, registrations } = useSelector((state: RootState) => state.user);
    const [startRegisterEvent, setStartRegisterEvent] = useState<string>();
    const [loadingDates, setLoadingDates] = useState<boolean>(false);
    const [eventTypeInfo, setEventType] = useState<EventType | null>(null);

    const [sorting, setSorting] = useState(true);
    const [sortedEvents, setSortedEvents] = useState<Array<Event>>();

    useEffect(() => {
        async function loadDates(clientId: string) {
            getAvailableEvents(clientId);
        }

        if (user) {
            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
            loadDates(clientId);
        }
        // eslint-disable-next-line
    }, [user, eventType, selectedCountry]);

    useEffect(() => {
        if (eventTypeInfo && eventTypeInfo?.events) setSortedEvents(getSortedEvents(eventTypeInfo?.events))
    },[sortBy, ascending, eventTypeInfo])

    useEffect(() => {
        if (sorting) {
            setTimeout(() => {
                setSorting(false);
            }, 300);
        }
    },[sortedEvents, sorting])

    useEffect(() => {
        history.replace("/");
    },[])

    const getFilteredEvents = (events: Event[]) => {
        if (events && selectedCountry === 'all') {
            events.map((e) => EV_COUNTRIES.push(e.city?.toLowerCase() + ', ' + e.country?.toLowerCase()));
            return events;
        } else if (selectedCountry !== 'all') {
            let temp = events.slice();
            return temp.filter((x) => x.city.toLowerCase() + ', ' + x.country.toLowerCase() === selectedCountry);
        }
        return [];
    }

    const getAvailableEvents = async (clientId: string) => {
        if (eventType) {
            setLoadingDates(true);
            getEventsByClientAndType(clientId, eventType.programCode)
                .then((eType) => {
                    var sortedEvents = {
                        eventType: eType.eventType,
                        programCode: eType.programCode,
                        description: eType.description,
                        events: getFilteredEvents(eType.events)
                    }
                    setEventType(sortedEvents);
                    setTimeout(() => {
                        setLoadingDates(false);
                    }, 500)
                }).catch((error) => {
                    console.error('getAvailableEvents', error);
                })
        }
    }

    const startRegister = async (event: Event, eventType: string) => {
        if (user) {
            logEvent(getAnalytics(),
					"Viewed Event",
					{
						"Event Name": event.title,
                        "Event ID / Code": event.programCode + " (" + event.eventKey + ")",
						"Event Location": (event.city + ", " + event.country)
					}
            );
            resetSelectedCountry();
            history.push('/event');
            dispatch(setEventSelected(JSON.stringify(event)));
            setStartRegisterEvent(event.eventKey);
        }
    }

    const getSortedEvents = (ev: Array<Event>) => {
        setSorting(true);
        return ev.sort((x,y) => 
            (sortBy === 'date')
                ? (ascending) ? (x.startDate >= y.startDate ? 1 : -1) : (x.startDate >= y.startDate ? -1 : 1)
            : (sortBy === 'location')
                ? (ascending) ? (x.city >= y.city ? 1 : -1) : (x.city >= y.city ? -1 : 1)
            //the last case is by 'seats'
            : (ascending) ? (x.availableSeats >= y.availableSeats ? 1 : -1) : (x.availableSeats >= y.availableSeats ? -1 : 1)
        )
    }

    return (
        <Fragment>
            
                <div className='border-top text-left'>
                    {
                        eventType
                            ?
                            <div style={{padding: "10px 30px", fontWeight: "bold", backgroundColor: "#FAF9FD"}} className={"noto border-bottom"}>{eventType.eventType}</div>
                            :
                            <div style={{padding: "10px 30px", backgroundColor: "#FAF9FD"}} className={"noto border-bottom"}>
                                No Events
                            </div>
                    }
                </div>
                <Card.Body style={{padding: "0px 13.5px"}} className='text-left'>
                    {
                        eventType
                            ?
                            <Fragment>
                                <div>
                                    {
                                        (loadingDates) ?
                                            <Fragment>
                                                <SkeletonTable />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                {
                                                    JSON.stringify(eventTypeInfo) === "{}" || eventTypeInfo?.events?.length === 0
                                                        ?
                                                        <Row className='p-10'>No dates available</Row>
                                                        :
                                                        sortedEvents && sortedEvents
                                                        .map((event: Event, index: number) => (
                                                        <Fragment key={index}>
                                                            {
                                                                <Row
                                                                    data-testid='event_dates_row'
                                                                    key={index}
                                                                    className={'noto'}
                                                                    style={{padding: "8px 40px", borderTop: (index!== 0) ? "1px solid #C4C6CF" : ""}}
                                                                >
                                                                    <Col xs='12'>
                                                                        <Row style={{alignItems: "center"}}>
                                                                            <Col xs='3' style={{opacity: (sorting && sortBy === 'date') ? 0.3 : 1}}>
                                                                                {moment(event.startDate).format('MMM DD, YYYY')} - {moment(event.endDate).format('MMM DD, YYYY')}
                                                                            </Col>
                                                                            <Col xs='2' style={{textTransform: "capitalize", textAlign: "center", opacity: (sorting && sortBy === 'location') ? 0.3 : 1}}>
                                                                                {event.city},<br/>{(event.country).toLowerCase()}
                                                                            </Col>
                                                                            <Col xs='4' style={{textAlign: "center", opacity: (sorting && sortBy === 'seats') ? 0.3 : 1}}>
                                                                                {event.availableSeats} of {event.totalSeats} Seats Available
                                                                            </Col>
                                                                
                                                                            <Col xs='3' style={{alignSelf: "center"}}>
                                                                                {
                                                                                    startRegisterEvent === event.eventKey 
                                                                                        ?
                                                                                        <Loading height={50} width={50} />
                                                                                        :
                                                                                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                                                            {
                                                                                                event.availableSeats > 0
                                                                                                &&
                                                                                                //DO NOT ENABLE THIS
                                                                                                // currentHolds?.length === 0
                                                                                                // &&
                                                                                                //
                                                                                                registrations?.length === 0
                                                                                                &&
                                                                                                eventSelected === ""
                                                                                                &&
                                                                                                <button
                                                                                                    data-testid='start_register_button'
                                                                                                    className='btn btn-outline-primary btn-sm'
                                                                                                    onClick={() => startRegister(event, eventType.eventType)}
                                                                                                >Register</button>
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </Col>
                                                                    </Row>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            </Fragment>  
                                                        ))
                                                }
                                                <Fragment>
                                                </Fragment>
                                            </Fragment>
                                    }
                                </div>
                            </Fragment>
                            :
                            <div style={{margin: "20px 20px"}}>There are no events available for registration. If you feel this is an error, please contact the CCL Support Desk at <a href="mailto:support@ccl.org">support@ccl.org</a>.</div>
                    }
                </Card.Body>
        </Fragment>
    )
}

const AvailableRegistrations: React.FC = () => {
    const { user } = useAuth0();

    const { eventTypes, eventsLoading } = useSelector((state: RootState) => state.user);
    const [sorter, setSorter] = useState('date');
    const [asc, setAsc] = useState(true);
    const [selectedGrade, setSelectedGrade] = useState("");
    const [filteredEventTypes, setFilteredEventTypes] = useState<Array<EventType>>([]);

    const [selectedLoc, setSelectedLoc] = useState('all');

    useEffect(() => {
        if (!eventsLoading && eventTypes) {
            setFilteredEventTypes(eventTypes);
        }
    }, [user, eventsLoading]);

    useEffect(() => {
    },[filteredEventTypes, selectedGrade, selectedLoc])

    const onDropdownSelect = (filter: string, choice: string) => {
        switch (filter) {
            case 'grade' : 
                setSelectedGrade(choice);
                switch (choice) {
                    case 'all': setFilteredEventTypes(eventTypes);
                    break;
                    default: setFilteredEventTypes(eventTypes.slice().filter((e: EventType) => e.programCode === choice));
                }
            break;
            case 'region' :
                setSelectedLoc(choice);
        }
    }
    
    const getGradeLabel = (grade: string) => {
        switch (grade) {
            case 'GSKLLP': return 'Grade 3';
            case 'GSKLLPD': return 'Grade 4'
            default: return grade;
        }
    }

    const returnCard = () => {
        if (eventsLoading) {
            return (
                <SkeletonCard />
            )
        } else if (!filteredEventTypes || filteredEventTypes.length === 0) {
            return (
                <DisplayEventType/>
            )
        } else {
            return (
                <Fragment>
                    <Row>
                        {
                            selectedGrade === "" &&
                            <Modal
                                show={true}
                                backdrop='static'
                                keyboard={false}
                                size='sm'
                                aria-labelledby='contained-modal-title-vcenter'
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id='contained-modal-title-vcenter' className="noto">
                                        Please select your grade
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="noto pl-5 pr-5">
                                    <Form.Control as="select" onChange={(e: any) => onDropdownSelect('grade', e.target.value)} size='lg' style={{width: 300}} className='border'>
                                        <option value={'all'}>All Grades</option>
                                        {
                                            eventTypes.length > 0 ?
                                            <Fragment>
                                                <Dropdown.Divider />
                                                {
                                                    eventTypes.map((reg, index) => 
                                                        <option key={index} value={reg.programCode}>{getGradeLabel(reg.programCode)}</option>
                                                    )
                                                }
                                            </Fragment>
                                            :
                                            <Fragment></Fragment>
                                        }
                                    </Form.Control> 
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    {/* <Button
                                        data-testid='clear_hold_button'
                                        className='btn btn-sm btn-danger'
                                        onClick={() => {
                                            setShowModal(false);
                                            dispatch(setEventSelected(""));
                                            dispatch(setProceededToPayment(false));
                                        }}
                                    >
                                        Go back to dashboard
                                    </Button> */}
                                    <Row className="noto">
                                        This will ensure the portal displays events tailored to your leader level. You can change this selection at any time using the drop down menu.
                                    </Row>
                                </Modal.Footer>
                            </Modal>
                        }
                        {/* FILTER ROW */}
                        <Col xs='12' style={{padding: "10px 65px", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <Form.Control as="select" onChange={(e: any) => onDropdownSelect('region', e.target.value)} size='lg' style={{width: 300}} className='border ml-10 select-dropdown-arrow'>
                                <option value={'all'}>All Locations</option>
                                {
                                    EV_COUNTRIES.length > 0 ?
                                    <Fragment>
                                        <Dropdown.Divider />
                                        {
                                            EV_COUNTRIES.slice().filter(function(item, pos, self) {
                                                return self.indexOf(item) == pos;
                                            }).sort((x,y) => x > y ? 1 : -1).map((reg, index) => 
                                                <option key={index} value={reg}>{reg}</option>
                                            )
                                        }
                                    </Fragment>
                                    :
                                    <Fragment></Fragment>
                                }
                            </Form.Control> 
                            <Form.Control value={selectedGrade} as="select" onChange={(e: any) => onDropdownSelect('grade', e.target.value)} size='lg' style={{width: 300}} className='border ml-10'>
                                <option value={'all'}>All Grades</option>
                                {
                                    eventTypes.length > 0 ?
                                    <Fragment>
                                        <Dropdown.Divider />
                                        {
                                            eventTypes.map((reg, index) => 
                                                <option key={index} value={reg.programCode}>{getGradeLabel(reg.programCode)}</option>
                                            )
                                        }
                                    </Fragment>
                                    :
                                    <Fragment></Fragment>
                                }
                            </Form.Control> 
                        </Col>

                        {/* SORTER ROW */}
                        <Card.Body style={{padding: "0px 13.5px"}} className='text-left'>
                            <Row
                                data-testid='event_dates_row'
                                className={'noto border-top'}
                                style={{padding: "8px 40px", marginLeft: 0, marginRight: 0}}
                            >
                                <Col xs='12'>
                                    <Row style={{alignItems: "center"}}>
                                        <Col xs='2' className='text-center' onClick={() => {setSorter('date'); setAsc((x) => !x)}} style={{cursor: "pointer", userSelect: "none"}}>
                                            Date&nbsp;&nbsp;&nbsp;
                                            { sorter === 'date' ? asc
                                                ? <i className='flaticon2-up icon-md text-muted'></i>
                                                : <i className='flaticon2-down icon-md text-muted'></i>
                                            : <i className='flaticon2-down icon-md text-light-muted'></i>
                                            }
                                        </Col>
                                        <Col xs='4' className='text-center' onClick={() => {setSorter('location'); setAsc((x) => !x)}} style={{cursor: "pointer", userSelect: "none"}}>
                                            Location&nbsp;&nbsp;&nbsp;
                                            { sorter === 'location' ? asc
                                                ? <i className='flaticon2-up icon-md text-muted'></i>
                                                : <i className='flaticon2-down icon-md text-muted'></i>
                                            : <i className='flaticon2-down icon-md text-light-muted'></i>
                                            }
                                        </Col>
                                        <Col xs='2' className='text-center' onClick={() => {setSorter('seats'); setAsc((x) => !x)}} style={{cursor: "pointer", userSelect: "none"}}>
                                            Seats Available&nbsp;&nbsp;&nbsp;
                                            { sorter === 'seats' ? asc
                                                ? <i className='flaticon2-up icon-md text-muted'></i>
                                                : <i className='flaticon2-down icon-md text-muted'></i>
                                            : <i className='flaticon2-down icon-md text-light-muted'></i>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Row>
                    {
                        filteredEventTypes.map((evtType, index) => { return (
                            evtType.events &&
                                <DisplayEventType sortBy={sorter} ascending={asc} eventType={evtType} key={index} selectedCountry={selectedLoc} resetSelectedCountry={() => setSelectedLoc('all')}/>
                        )})
                    }
                </Fragment>
            )
        }
    }

    return (
        <Card
            data-testid='display_event_card'
            className='card-custom gutter-b'
            style={{marginBottom: "5%"}}
        >
            <Card.Header style={{alignItems: 'center', justifyContent: 'flex-start'}}>
                <div className='noto card-title font-weight-bolder text-left mr-5'>
                    Events
                </div>
                <img src={calendaricon} style={{height: "1.25%", width: "1.25%"}}/>
            </Card.Header>
            {returnCard()}
        </Card>
    )
};

export default AvailableRegistrations;
