import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Modal, Card } from 'react-bootstrap';
import moment from 'moment';

import { useAuth0 } from '@auth0/auth0-react';
import { useInterval } from '../utils/useInterval';

import { RootState } from '../store';
import { fetchCurrentHolds, setCurrentEventType, setEventSelected, setProceededToPayment } from '../store/User/UserSlice';
import StripeForm from './StripeForm';
import { confirmHold, getEventByKey, removeHold } from '../services/apiService';
import { Event } from '../@types/event.d';
import ok_icon from '../assets/icons/ok.jpg'
import error_icon from '../assets/icons/error.jpg'

import { Loading } from '../components';
import SelectedEvent from './SelectedEvent';
import { BlueBanner } from './BlueBanner';
import { useHistory } from 'react-router-dom';

const PendingRegistrations: React.FC = () => {
    const dispatch = useDispatch();
    const { user } = useAuth0();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [orderIsSuccesful, setOrderIsSuccesful] = useState<boolean>(false);
    const [orderIsUnsuccesful, setOrderIsUnsuccesful] = useState<boolean>(false);
    const [isClearing, setIsClearing] = useState<boolean>(false);

    const [holdId, setHoldId] = useState<string>('');
    const [event, setEvent] = useState<Event>({} as Event);
    const [diffSecs, setDiffSecs] = useState<number>(900);
    const [checkInterval, setCheckInterval] = useState<number>(0);
    const { currentHolds, currentEventType, eventSelected } = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const hasPendingHold = (): boolean => {
        let pendingHolds = currentHolds?.filter(x => x.status === "Pending")
        return pendingHolds && JSON.stringify(pendingHolds) !== JSON.stringify([]);
    }

    const clearHold = async (confirm: boolean) => {
        setDiffSecs(900);

        if (user && hasPendingHold()) {
            setIsClearing(true);

            var acctId = user['http://schemas.ccl.org/accounts/claims/account/id'];
            try {
                if (confirm) {
                    await confirmHold(acctId, currentHolds[0].holdId);
                } else {
                    await removeHold(acctId, currentHolds[0].eventKey);
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(fetchCurrentHolds(user));
                dispatch(setCurrentEventType(""));
                setIsClearing(false);
            }
        }
    }

    useEffect(() => {
        async function loadEvent(eventKey: string, clientId: string) {
            let event = await getEventByKey(eventKey, clientId);
            setEvent(event);
        }

        if (user && currentHolds && currentHolds.length > 0) {
            setCheckInterval(30000);

            let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
            loadEvent(currentHolds[0].eventKey, clientId);
        } else {
            setCheckInterval(0);
        }
        // eslint-disable-next-line
    }, [currentHolds, eventSelected]);

    useEffect(() => {
    },[orderIsSuccesful, orderIsUnsuccesful]);

    useEffect(() => {
        //We do not want back navigation to disturb the state, so we control it
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                setShowModal(true);
                return false;
            }
        });
    
        return () => {
          unblock();
        };
      }, [history]);

    useInterval(
        () => {
            let pendingHolds = currentHolds?.filter(x => x.status === "Pending")
            if (pendingHolds && pendingHolds.length > 0 && pendingHolds[0].expiryUTCDate) {
                let now = moment().utc();
                let expiry = moment.utc(currentHolds[0].expiryUTCDate);

                let d = expiry.diff(now, "second");
                if (d < 0) {
                    setCheckInterval(0);
                    clearHold(false);
                } else {
                    setDiffSecs(d);
                }
            }
            console.log('diffSecs', diffSecs);
        },
        // Delay in milliseconds or null to stop it
        checkInterval > 0 ? checkInterval : null,
    )

    const renderCountdown = () => {
        let className = "noto ";
        if (diffSecs < 300) {
            className += "text-danger";
        }

        return (
            <div className={className}>
                Please enter your information below by <b>{moment.utc(currentHolds[0].expiryUTCDate).local().format('h:mma')}</b> to complete your registration.
            </div>
        )
    }

    return (
        <Fragment>
            {!orderIsSuccesful &&
                <BlueBanner>
                    {renderCountdown()}
                </BlueBanner>
            }
            {
                eventSelected !== "" &&
                <SelectedEvent hideAbout={true}/>
            }
            {
                hasPendingHold() && !orderIsSuccesful
                &&
                <Fragment>
                    <Card
                        data-testid='display_event_card'
                        className='card-custom gutter-b'
                        style={{marginBottom: orderIsUnsuccesful ? 40 : 120}}
                    >
                        <Card.Header>
                            <div className='noto card-title font-weight-bolder text-left'>
                                {orderIsSuccesful ? <>Successful</> : <>Pending</>} Registration
                            </div>
                        </Card.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col sm='12'>
                                        <div className="alert alert-light alert-default noto" role="alert">
                                            <div className="alert-text">
                                                <span className='font-size-h6 font-weight-bold'>You are {orderIsSuccesful ? "registered" : "registering"} for:</span>
                                                <Row>
                                                    <Col sm='12'>
                                                        <strong>{JSON.parse(eventSelected).title} ({currentEventType})</strong>
                                                    </Col>
                                                    <Col sm='12'>
                                                        <strong>
                                                            {moment(event.startDate).format('MMM DD, YYYY')} - {moment(event.endDate).format('MMM DD, YYYY')}
                                                        </strong>
                                                    </Col>
                                                    <Col sm='12'>
                                                        <label>Description:</label> {
                                                            event.description == null || event.description === ""
                                                                ? JSON.parse(eventSelected).description
                                                                : event.description
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    data-testid='stripe_form'
                                >
                                    <StripeForm
                                        onProcessing={() => {
                                            setIsProcessing(true);
                                        }}
                                        onSuccess={(holdId: string) => {
                                            setOrderIsSuccesful(true);
                                            setHoldId(holdId);
                                            setIsProcessing(false);
                                            setOrderIsUnsuccesful(false);
                                        }}
                                        onError={() => {
                                            setIsProcessing(false);
                                            setOrderIsSuccesful(false);
                                            setOrderIsUnsuccesful(true);
                                        }}
                                        onComplete={() => {
                                            setIsProcessing(false);
                                        }}
                                        onCancel={() => {
                                            setOrderIsSuccesful(false);
                                            setOrderIsUnsuccesful(false);
                                        }} />
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                isProcessing
                                    ?
                                    <Loading height={50} width={50} />
                                    :
                                    <Fragment>
                                        {
                                            holdId !== currentHolds[0].holdId
                                                ?
                                                <Button
                                                    data-testid='clear_hold_button'
                                                    disabled={isClearing}
                                                    className='btn btn-sm btn-outline-danger'
                                                    onClick={() => setShowModal(true)}>
                                                    Cancel Registration
                                                </Button>
                                                :
                                                <Button
                                                    data-testid='clear_hold_button'
                                                    disabled={isClearing}
                                                    className='btn btn-sm btn-outline-primary'
                                                    onClick={() => {clearHold(true); dispatch(setEventSelected(""))}}>
                                                    Close
                                                </Button>
                                        }
                                        <Modal
                                        show={showModal}
                                        backdrop='static'
                                        keyboard={false}
                                        size='sm'
                                        aria-labelledby='contained-modal-title-vcenter'
                                        centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title id='contained-modal-title-vcenter' className="noto">
                                                    Are You Sure?
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Row className="noto pl-5 pr-5">
                                                    By leaving this page all information you have filled out will be discarded. 
                                                    <br/><br/>
                                                    You will not be registered for this event.
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    data-testid='clear_hold_button'
                                                    className='btn btn-sm btn-outline-primary'
                                                    onClick={() => {
                                                        setShowModal(false);
                                                        
                                                    }}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    data-testid='clear_hold_button'
                                                    className='btn btn-sm btn-danger'
                                                    onClick={() => {
                                                        setShowModal(false);
                                                        clearHold(false);
                                                        dispatch(setEventSelected(""));
                                                        dispatch(setProceededToPayment(false));
                                                    }}
                                                >
                                                    Go back to dashboard
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Fragment>
                            }
                        </Modal.Footer>
                    </Card>
                </Fragment>
            }
            {
                (orderIsSuccesful || orderIsUnsuccesful) &&
                <Fragment>
                    <Card
                        data-testid='display_event_card'
                        className='card-custom gutter-b'
                        style={{marginBottom: 30}}
                    >
                        <Card.Header>
                            <div className='noto card-title font-weight-bolder text-left'>
                                Payment {orderIsSuccesful ? 'Confirmed' : 'Error'} <img src={orderIsSuccesful ? ok_icon : error_icon} style={{marginLeft: 10, height: 15, width: 15}}/>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className='noto card-title text-left'>
                                {orderIsSuccesful ? 'Your Payment Has Been Accepted.' : 'There was a problem processing your card payment.'}
                            </div>
                            <div className='noto card-title text-left'>
                                {orderIsSuccesful ? 'Your registration is being processed and will appear on your dashboard soon.' : 'Please submit an email to GSK-CCLParticipantSupport@ccl.org for additional assistance.'}
                            </div>
                            <button
                                data-testid='start_register_button'
                                className='btn btn-outline-primary btn-sm'
                                onClick={() => {dispatch(setEventSelected("")); clearHold(orderIsSuccesful)}}
                            >Return to Home Page</button>
                        </Card.Body>
                    </Card>
                </Fragment>
            }
        </Fragment >
    );
};

export default PendingRegistrations;
